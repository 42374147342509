import { configureStore } from "@reduxjs/toolkit";

import { plunkApi } from "../api/plunkApi";
import rootReducers from "./rootReducer";
import { api } from "../api/api";

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
        api.middleware,
        plunkApi.middleware,
    ]),
})
