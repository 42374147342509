import React, { useState } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'

import { useGetUserProfileQuery, useUpdateUserEmailMutation } from '../api/api';
import { setUser } from '../redux/appSlice';
import { app } from '../firebase/config';

const auth = getAuth(app);

const AuthContext = React.createContext();

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authorized, setAuthorized] = useState('')
    const profile = useSelector((state) => state.app.profile)
    const user = useSelector((state) => state.app.user)

    const dispatch = useDispatch()

    const [updateUserEmail] = useUpdateUserEmailMutation()
    useGetUserProfileQuery(undefined, { skip: !user })

    React.useEffect(() => {
        if (user && profile && user.email !== profile.email) {
            updateUserEmail({ email: user.email })
        }
    }, [user, profile, updateUserEmail])

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser({ user: user }))
                setAuthorized('authorized')
            } else {
                dispatch(setUser({ user: null }))
                setAuthorized('unauthorized')
            }
        });
    }, [dispatch]);

    const value = {
        authorized,
    };

    return (
        <AuthContext.Provider value={value} >
            {children}
        </AuthContext.Provider >
    );
}
