import React, { useEffect, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { PLANS } from '../../utils/constants';

import PayPalButton from '../PayPalButton/PayPalButton';

import CheckoutCreditCardWhite from '../../assets/icons/checkout-credit-card-white.svg'
import CheckoutBackMobileWhite from '../../assets/icons/checkout-back-mobile-white.svg'
import CheckoutBackMobileBlack from '../../assets/icons/checkout-back-mobile-black.svg'
import CheckoutCreditCard from '../../assets/icons/checkout-credit-card.svg'
import CancelAnytimeWhite from '../../assets/icons/cancel-anytime-white.svg'
import PoweredByStripeBlack from '../../assets/powered-by-stripe-black.png'
import PoweredByStripeWhite from '../../assets/powered-by-stripe-white.png'
import CancelAnytimeDark from '../../assets/icons/cancel-anytime-dark.svg'
import MoneyBackWhite from '../../assets/icons/money-back-white.svg'
import CheckoutPaypal from '../../assets/icons/checkout-paypal.svg'
import MoneyBackDark from '../../assets/icons/money-back-dark.svg'

const StripeForm = ({ planName, priceId }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [selectedPaymnetMethod, setSelectedPaymnetMethod] = useState('card')
    const [selectedPlan, setSelectedPlan] = useState({})

    const navigate = useNavigate()
    const elements = useElements();
    const stripe = useStripe();

    const { isDark } = useTheme()

    const paymentElementOptions = {
        layout: "tabs"
    }

    const handleBackToPlans = () => {
        navigate('/plans')
    }

    const handleNavigateToChangePlan = () => {
        navigate(`/plans?planName=${planName}&download=true`)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/upgrade-success/${planName}`,
            },
        })

        if (error) {
            setError(true)
            setLoading(false)
        }
    };

    useEffect(() => {
        if (planName) {
            setSelectedPlan(PLANS.find((el) => el.name === planName))
        }
    }, [planName])

    return (
        <form onSubmit={handleSubmit} className="checkout-form w-full">
            <div className="checkout-page pb-4">
                <div className="checkout-body">
                    <div className="checkout-field-block">
                        {error && (
                            <div className='checkout-error-block'>
                                Oops! It seems there was an issue processing your payment.
                            </div>
                        )}
                        <div className='checkout-new-title-powered'>
                            <div className="checkout-body-header">Secure Checkout</div>
                            {isDark ? (
                                <img width={120} src={PoweredByStripeWhite} alt="" />
                            ) : (
                                <img width={120} src={PoweredByStripeBlack} alt="" />
                            )}
                        </div>
                        <div className="checkout-card-field">
                            <span className="checkout-new-payment-method-title">
                                Payment Method
                            </span>
                            <div className='select-payments-block-wrapper'>
                                <div onClick={() => setSelectedPaymnetMethod('card')} className={`selecet-payment-method-element ${selectedPaymnetMethod === 'card' ? 'active' : ''}`}>
                                    {isDark ? (
                                        <img src={CheckoutCreditCardWhite} alt="" />
                                    ) : (
                                        <img src={CheckoutCreditCard} alt="" />
                                    )}
                                    <span className='select-payment-method-name'>Credit Card</span>
                                </div>
                                <div onClick={() => setSelectedPaymnetMethod('paypal')} className={`selecet-payment-method-element ${selectedPaymnetMethod === 'paypal' ? 'active' : ''}`}>
                                    <img src={CheckoutPaypal} alt="" />
                                    <span className='select-payment-method-name'>PayPal</span>
                                </div>
                            </div>
                        </div>
                        <div className='payment-checkout-underline'></div>
                        <div className='payment-element-wrapper'>
                            {selectedPaymnetMethod === 'card' ? (
                                <PaymentElement id="payment-element" options={paymentElementOptions} />
                            ) : (
                                <div className='payment-paypal-block'>
                                    <img width={36} src={CheckoutPaypal} alt="" />
                                    <span className='payment-paypal-block-text'>Click below to pay through PayPal</span>
                                </div>
                            )}
                        </div>
                        <div className='checkout-new-selected-plan-block'>
                            <div className='change-selected-plan-block'>
                                <div className='change-selected-plan-block-info'>
                                    <span className='change-selected-plan-block-title'>Selected Package</span>
                                    <span className='change-selected-plan-block-name'>{selectedPlan?.name} {selectedPlan?.type}</span>
                                </div>
                                <span onClick={handleNavigateToChangePlan} className='change-selected-plan-block-change-btn'>Change Package</span>
                            </div>
                            <div className='checkout-new-due-total-block'>
                                <span className='checkout-new-due-total-title'>Due today:</span>
                                <span className='checkout-new-due-total-price'>${selectedPlan.price}</span>
                            </div>
                        </div>
                        <div className='stripe-purchase-button'>
                            {selectedPaymnetMethod === 'card' ? (
                                <button
                                    id="submit"
                                    disabled={loading}
                                    className="checkout-button relative"
                                >
                                    {loading && (
                                        <svg style={{ position: 'absolute', left: 'calc(50% - 85px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                            <circle r="20" cy="50" cx="50"></circle>
                                        </svg>
                                    )}
                                    Purchase
                                </button>
                            ) : (
                                <>
                                    <PayPalButton price={selectedPlan.price} planName={planName} selectedPlan={selectedPlan} setError={setError} />
                                </>
                            )}
                            {planName === 'Premium' && (
                                <span className='new-flow-checkout-text'>
                                    By clicking “Purchase” you agree to be charged $47.40 now and accept our Terms of Use and Privacy Policy. You will be billed $47.40 each year. You can cancel anytime.
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="checkout-body-right">
                        <div className='checkout-guarantee-block disable-on-desktop'>
                            <span className='new-flow-checkout-text'>
                                By clicking “Purchase” you agree to be charged $47.40 now and accept our Terms of Use and Privacy Policy. You will be billed $47.40 each year. You can cancel anytime.
                            </span>
                        </div>
                        <div className='checkout-guarantee-underline disable-on-desktop'></div>
                        <div className='checkout-guarantee-block'>
                            <div className='checkout-guarantee-icon-wrapper'>
                                {isDark ? (
                                    <img src={MoneyBackDark} alt="" />
                                ) : (
                                    <img src={MoneyBackWhite} alt="" />
                                )}
                            </div>
                            <span className='checkout-guarantee-block-title'>Money-back guarantee</span>
                            <span className='checkout-guarantee-block-text'>If you are not fully satisfied within the 14 day trial period, just let us know and we will happily process a full refund.</span>
                        </div>
                        <div className='checkout-guarantee-underline'></div>
                        <div className='checkout-guarantee-block'>
                            <div className='checkout-guarantee-icon-wrapper'>
                                {isDark ? (
                                    <img src={CancelAnytimeDark} alt="" />
                                ) : (
                                    <img src={CancelAnytimeWhite} alt="" />
                                )}
                            </div>
                            <span className='checkout-guarantee-block-title'>Cancel anytime</span>
                            <span className='checkout-guarantee-block-text'>You may cancel anytime from your dashboard, or by email.</span>
                        </div>
                        <div style={{ marginBottom: '72px' }} className='checkout-guarantee-underline disable-on-desktop'></div>
                    </div>
                    <div onClick={handleBackToPlans} className='secure-checkout-block-mobile'>
                        {isDark ? (
                            <img src={CheckoutBackMobileWhite} alt="" />
                        ) : (
                            <img src={CheckoutBackMobileBlack} alt="" />
                        )}
                        <span className='secure-checkout-text-mobile'>Secure Checkout</span>
                        <span></span>
                    </div>
                    <div className='change-selected-plan-block-mobile'>
                        <div className='change-selected-plan-block-info'>
                            <span className='change-selected-plan-block-title'>Selected Package</span>
                            <span className='change-selected-plan-block-name'>{selectedPlan?.name} {selectedPlan?.type}</span>
                        </div>
                        <span onClick={handleNavigateToChangePlan} className='change-selected-plan-block-change-btn'>Change Package</span>
                    </div>
                    <div className='checkout-new-purchase-block'>
                        <div className='checkout-new-due-total-block w-full'>
                            <span className='checkout-new-due-total-title'>Due today:</span>
                            <span className='checkout-new-due-total-price'>${selectedPlan.price}</span>
                        </div>
                        {selectedPaymnetMethod === 'card' ? (
                            <button
                                id="submit"
                                disabled={loading}
                                className="checkout-new-purchase-button relative"
                            >
                                {loading && (
                                    <svg style={{ position: 'absolute', left: 'calc(50% - 100px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                )}
                                Purchase
                            </button>
                        ) : (
                            <>
                                <PayPalButton height={56} price={selectedPlan.price} planName={planName} selectedPlan={selectedPlan} setError={setError} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </form>
    )
}

export default StripeForm