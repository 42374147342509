import { combineReducers } from "@reduxjs/toolkit"

// Reducers
import appReducer from './appSlice'

// RTK Query API
import { api } from "../api/api"
import { plunkApi } from "../api/plunkApi"

const rootReducers = combineReducers({
    app: appReducer,
    [api.reducerPath]: api.reducer,
    [plunkApi.reducerPath]: plunkApi.reducer,
})

export default rootReducers
