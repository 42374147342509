import React from 'react'
import { useNavigate } from 'react-router-dom';

import { FooterBlock } from '../../styles/styles';

import Facebook from '../../assets/mainPage/facebook.svg';
import Instagram from '../../assets/mainPage/instagram.svg';
import Linkedin from '../../assets/mainPage/linkedin.svg';
import Twitter from '../../assets/mainPage/twitter.svg';

const Footer = () => {
    const navigate = useNavigate()

    const handleNavigateTo = (path) => {
        navigate(path)
    }

    return (
        <FooterBlock>
            <div className="max-w-[1440px] p-[120px] m-auto lg:px-0 lg:py-[40px] flex flex-col gap-[80px] lg:py-[40px] lg:px-[16px]">
                <div className="flex justify-between gap-[16px] items-start lg:flex-col-reverse lg:items-center lg:gap-[40px]">
                    <div className="flex flex-col gap-[16px]">
                        <div className="flex gap-[16px] lg:justify-center">
                            <div onClick={() => handleNavigateTo('/privacy-policy')} className="text-[18px] text-[#A0A0A0] font-medium lg:text-[14px] cursor-pointer">Privacy Policy</div>
                            <div onClick={() => handleNavigateTo('/terms-and-conditions')} className="text-[18px] text-[#A0A0A0] font-medium lg:text-[14px] cursor-pointer">Terms of Service</div>
                        </div>
                        <div className="text-[18px] text-[#A0A0A0] font-medium lg:text-[12px] lg:text-center">
                            © 2023, SwiftLogo
                        </div>
                    </div>
                    <div className="flex gap-[16px] justify-center">
                        <img src={Facebook} alt="" />
                        <img src={Instagram} alt="" />
                        <img src={Linkedin} alt="" />
                        <img src={Twitter} alt="" />
                    </div>
                </div>
            </div>
        </FooterBlock>
    )
}

export default Footer