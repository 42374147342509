import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LogoMakerStepSixBlock } from '../../styles/styles'
import { useCreateLogosQuery } from '../../api/api'
import { imageColorToFilter } from '../../utils/functions'
import { useEditorContext } from '../../context/EditorContext'
import { useAuthContext } from '../../context/AuthContext'
import { COLOR_SCHEMAS, LOGO_STYLES } from '../../utils/constants'

import Loader from '../Loader/Loader'
import AuthModal from '../../modals/AuthModal'

import AuthModalImage from '../../assets/auth-modal-image.svg'

const LogoMakerStepSix = () => {
    const [settings, setSettings] = useState({})
    const [iconsKeywords, setIconsKeywords] = useState([])
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedFontTypes, setSelectedFontTypes] = useState([])
    const [selectedLayouts, setSelectedLayouts] = useState([])
    const user = useSelector((state) => state.app.user)
    const profile = useSelector((state) => state.app.profile)

    const navigate = useNavigate()

    const { authorized } = useAuthContext()
    const { generatedLogos, setGeneratedLogos } = useEditorContext()

    const { data, isLoading } = useCreateLogosQuery({
        limit: 6,
        query: iconsKeywords.length > 0 ? iconsKeywords.join(',') : 'arrow',
        company_name: settings?.companyName,
        slogan: settings?.slogan,
        color_type: selectedColors,
        font_group: selectedFontTypes,
        layout: selectedLayouts,
    },
        { skip: !settings?.companyName || !iconsKeywords.length || !user || !profile, refetchOnMountOrArgChange: true }
    )

    const handleEditLogo = (id) => {
        navigate(`/editor/${id}`)
    }

    useEffect(() => {
        if (authorized === 'unauthorized') {
            setShowAuthModal(true)
        }
    }, [authorized])

    useEffect(() => {
        if (data && data.length > 0) {
            setGeneratedLogos([...generatedLogos, ...data])
        }
    }, [data])

    useEffect(() => {
        const newImage = new Image();
        newImage.src = AuthModalImage;
        window[AuthModalImage] = newImage;
    }, [])

    useEffect(() => {
        const savedSettings = localStorage.getItem('maker_state')

        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings)
            const { iconsType, selectedIcons, selectedColors, selectedStyles } = parsedSettings

            const keywords = []
            const colors = []
            const fontTypes = []
            const layouts = []

            if (selectedColors && selectedColors.length > 0) {
                COLOR_SCHEMAS.forEach((el) => {
                    if (selectedColors.includes(el.id)) {
                        colors.push(el.name)
                    }
                })

                setSelectedColors(colors)
            }

            if (selectedStyles && selectedStyles.length > 0) {
                LOGO_STYLES.forEach((el) => {
                    if (selectedStyles.includes(el.id)) {
                        fontTypes.push(el.font_group)
                        layouts.push(el.layout)
                    }
                })

                setSelectedFontTypes(fontTypes)
                setSelectedLayouts(layouts)
            }

            if (iconsType && iconsType.length > 0) {
                keywords.push(...iconsType)
            } else if (selectedIcons && selectedIcons.length > 0) {
                selectedIcons.forEach((icon) => {
                    keywords.push(icon.split(/(?=[A-Z])/)[2])
                })
            }

            setIconsKeywords(keywords)
            setSettings(parsedSettings)
        }
    }, [])

    return (
        <LogoMakerStepSixBlock>
            <div className="flex justify-between items-center">
                <div>
                    <h4 className="heading-text">Pick a logo to customize</h4>
                    <p className="description-text">Create a design preview and see other options.</p>
                </div>
            </div>

            {isLoading ? (
                <div className='absolute left-[50%] top-[50%] translate-x-[-50%]'>
                    <Loader />
                </div>
            ) : (
                <div className="generated-logos-list">
                    {generatedLogos.map((template) => (
                        <div
                            style={{
                                backgroundColor: `${template.background_color}`,
                                flexDirection: `${template.layout === 'top' ? 'column' : template.layout === 'bottom' ? 'column-reverse' : template.layout === 'left' ? 'row' : 'row-reverse'}`
                            }}
                            key={template.id}
                            className='generated-logo-wrapper'
                        >
                            <div
                                style={{ transform: `translateX(${template.logo_horizontal_position}px) translateY(${template.logo_vertical_position}px)` }}
                                className='generated-logo-icon'
                            >
                                <img
                                    style={{
                                        width: `${template.image_size}px`,
                                        filter: `${imageColorToFilter(template.image_color)}`,
                                    }}
                                    draggable={false}
                                    className='generated-logo-icon'
                                    src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(template.icon)}`}
                                    alt=""
                                />
                            </div>
                            <div className='generated-logo-text'>
                                <span
                                    style={{
                                        color: `${template.company_name_color}`,
                                        fontFamily: `${template.company_name_font_family}`,
                                        fontSize: `${template.company_name_size}px`,
                                        transform: `translateX(${template.company_name_horizontal_position}px) translateY(${template.company_name_vertical_position}px)`,
                                        letterSpacing: `${template.company_name_spacing}px`,
                                        textTransform: `${template.company_name_transform}`,
                                        textAlign: `${template.layout === 'top' ? 'center' : template.layout === 'bottom' ? 'center' : template.layout === 'left' ? 'start' : 'end'}`
                                    }}
                                    className='generated-logo-company-name'
                                >
                                    {settings?.companyName}
                                </span>
                                {(template.defaultSlogan || settings.slogan) && (
                                    <span
                                        className='generated-logo-slogan'
                                        style={{
                                            color: `${template.slogan_color}`,
                                            fontFamily: `${template.slogan_font_family}`,
                                            fontSize: `${template.slogan_size}px`,
                                            transform: `translateX(${template.slogan_horizontal_position}px) translateY(${template.slogan_vertical_position}px)`,
                                            letterSpacing: `${template.slogan_spacing}px`,
                                            textTransform: `${template.slogan_transform}`,
                                            textAlign: `${template.layout === 'top' ? 'center' : template.layout === 'bottom' ? 'center' : template.layout === 'left' ? 'start' : 'end'}`
                                        }}
                                    >
                                        {settings.slogan ? settings.slogan : template.defaultSlogan}
                                    </span>
                                )}
                                <div onClick={() => handleEditLogo(template.id)} className='generated-logo-customize-button'>See options & customize</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    handleClose={() => setShowAuthModal(false)}
                    signUp={true}
                    image={AuthModalImage}
                />
            )}
        </LogoMakerStepSixBlock>
    )
}

export default LogoMakerStepSix