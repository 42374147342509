import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { useViewport } from '../hooks/useViewport';

import { signInWithEmailPassword, signUpWithEmailPassword } from '../firebase/auth/emailPasswordAuth';
import { AuthModalBlock, AuthModalBody } from '../styles/styles'
import { loginSchema, signUpSchema } from '../utils/validators'
import { useSendEmailOnSignUpMutation } from '../api/plunkApi';
import { FIREBASE_ERRORS_TO_TEXT } from '../utils/constants';
import { googleSignIn } from '../firebase/auth/googleAuth';

import AuthClose from '../assets/icons/auth-close.svg'
import AuthMobileClose from '../assets/icons/auth-mobile-close.svg'
import AuthMobileCloseWhite from '../assets/icons/auth-mobile-close-white.svg'
import GoogleIcon from '../assets/icons/google-icon.svg'

const AuthModal = ({ show, handleClose, signUp, image }) => {
    const [isSignUp, setIsSignUp] = useState(false)
    const [showLoginElements, setShowLoginElements] = useState(false)
    const [showSignUpElements, setShowSignUpElements] = useState(false)
    const [showPasswordOnSignUp, setShowPasswordOnSignUp] = useState(false)
    const [showPasswordOnLogin, setShowPasswordOnLogin] = useState(false)
    const [error, setError] = useState('')

    const { isDark } = useTheme()
    const { isMobile } = useViewport()

    const [sendSignUpEmail] = useSendEmailOnSignUpMutation()

    const { register, handleSubmit, getValues, formState: { errors }, reset } = useForm({
        resolver: yupResolver(loginSchema)
    });

    const { register: register2, handleSubmit: handleSubmit2, getValues: getValues2, formState: { errors: errors2 }, reset: resetSignUp } = useForm({
        resolver: yupResolver(signUpSchema)
    });

    const handleChangeLoginValues = () => {
        const values = getValues()

        const result = Object.keys(values).some((key) => values[key] !== '')

        setShowLoginElements(result)
    }

    const handleChangeSignUpValues = () => {
        const values = getValues2()

        const result = Object.keys(values).some((key) => values[key] !== '')

        setShowSignUpElements(result)
    }

    const handleSignUp = async (data) => {
        const { error } = await signUpWithEmailPassword({ password: data.password, email: data.email })

        if (error) {
            setError(error)
        } else {
            handleClose()
            sendSignUpEmail({ email: data.email, domain: `${window.location.host}` })
        }
    }

    const handleSignIn = async (data) => {
        const { error } = await signInWithEmailPassword({ ...data })

        if (error) {
            setError(error)
        } else {
            handleClose()
        }
    }

    const handleGoogleSignIn = async () => {
        const { error } = await googleSignIn()

        if (error) {
            setError('Invalid credentials provided, please try again.')
        } else {
            handleClose()
        }
    }

    const handleSwitchAuthType = () => {
        setIsSignUp(!isSignUp)
        setError('')
        reset()
        resetSignUp()
    }

    useEffect(() => {
        setIsSignUp(signUp)
    }, [signUp])

    return (
        <AuthModalBlock centered show={show} onHide={handleClose}>
            <AuthModalBody>
                <div className='left-side'>
                    <h1 className='heading'>{isSignUp ? 'Sign up' : 'Log in'}</h1>
                    <p className='description'>
                        {isSignUp ?
                            'Create an account to save your design.' :
                            <>
                                Start designing a brand
                                {isMobile && (
                                    <br />
                                )}
                                you love 💛
                            </>
                        }
                    </p>
                    {isSignUp ? (
                        <form className='w-full flex flex-col' onSubmit={handleSubmit2(handleSignUp)}>
                            <div className='inputs-block'>
                                <div className='input-block-group'>
                                    <input
                                        placeholder='Email address'
                                        className={`input ${errors2.email ? 'input-error' : ''}`}
                                        type="text"
                                        {...register2('email', {
                                            onChange: () => handleChangeSignUpValues(),
                                        })}
                                    />
                                </div>
                                <div className='input-block-group relative'>
                                    {showPasswordOnSignUp ? (
                                        <LuEye onClick={() => setShowPasswordOnSignUp(false)} color="#A0A0A0" size={24} className='password-show-hide-icon' />
                                    ) : (
                                        <LuEyeOff onClick={() => setShowPasswordOnSignUp(true)} color="#A0A0A0" size={24} className='password-show-hide-icon' />
                                    )}
                                    <input
                                        placeholder='Password'
                                        className={`input ${showPasswordOnSignUp ? '' : 'text-hidden-password'} ${errors2.password ? 'input-error' : ''}`}
                                        type="text"
                                        {...register2('password', {
                                            onChange: () => handleChangeSignUpValues(),
                                        })}
                                    />
                                </div>
                            </div>
                            {showSignUpElements && (
                                <button className='submit-button'>Sign up</button>
                            )}
                            {(errors2 || error) && error ? (
                                <span className='login-form-error'>
                                    {FIREBASE_ERRORS_TO_TEXT[error] ? FIREBASE_ERRORS_TO_TEXT[error] : 'Something went wrong on our end. Apologies for the inconvenience. Please try again later or contact support.'}
                                </span>
                            ) : Object.keys(errors2).map((errorKey, index) => {
                                if (index === 0) {
                                    return (
                                        <span key={index} className='login-form-error'>{errors2[errorKey].message}</span>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </form>
                    ) : (
                        <form onSubmit={handleSubmit(handleSignIn)} className='w-full flex flex-col'>
                            <div className='inputs-block'>
                                <div className='input-block-group'>
                                    <input
                                        placeholder='Email address'
                                        className={`input ${errors.email ? 'input-error' : ''}`}
                                        type="text"
                                        {...register('email', {
                                            onChange: () => handleChangeLoginValues(),
                                        })}
                                    />
                                </div>
                                <div className='input-block-group relative'>
                                    {showPasswordOnLogin ? (
                                        <LuEye onClick={() => setShowPasswordOnLogin(false)} color="#A0A0A0" size={24} className='password-show-hide-icon' />
                                    ) : (
                                        <LuEyeOff onClick={() => setShowPasswordOnLogin(true)} color="#A0A0A0" size={24} className='password-show-hide-icon' />
                                    )}
                                    <input
                                        placeholder='Password'
                                        className={`input ${showPasswordOnLogin ? '' : 'text-hidden-password'} ${errors.password ? 'input-error' : ''}`}
                                        type="text"
                                        {...register('password', {
                                            onChange: () => handleChangeLoginValues(),
                                        })}
                                    />
                                </div>
                            </div>
                            {showLoginElements && (
                                <button className='submit-button'>Sign in</button>
                            )}
                            {(errors || error) && error ? (
                                <span className='login-form-error'>
                                    {FIREBASE_ERRORS_TO_TEXT[error] ? FIREBASE_ERRORS_TO_TEXT[error] : 'Something went wrong on our end. Apologies for the inconvenience. Please try again later or contact support.'}
                                </span>
                            ) : Object.keys(errors).map((errorKey, index) => {
                                if (index === 0) {
                                    return (
                                        <span key={index} className='login-form-error'>{errors[errorKey].message}</span>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </form>
                    )}
                    <div className='socials-auth-block'>
                        <button onClick={handleGoogleSignIn} className='social-button'>
                            <img className='social-button-icon' src={GoogleIcon} alt="" />
                            {isSignUp ? 'Sign up' : 'Log in'}  with Google
                        </button>
                    </div>
                    <Link className='auth-terms-link'>
                        Terms and services apply
                    </Link>
                    <div className='auth-underline-with-or'>
                        <span className='underline-or-text'>OR</span>
                    </div>
                    <div className='auth-switch-block'>
                        {isSignUp ? 'Already have an account?' : 'Don’t have an account?'}
                        <span onClick={handleSwitchAuthType} className='auth-continue-guest cursor-pointer'>
                            {isSignUp ? 'Log in' : 'Sign up'}
                        </span>
                    </div>
                    {
                        isDark ? (
                            <img onClick={handleClose} className='auth-mobile-close' src={AuthMobileCloseWhite} alt="" />
                        ) : (
                            <img onClick={handleClose} className='auth-mobile-close' src={AuthMobileClose} alt="" />
                        )
                    }
                </div>
                <div className='right-side'>
                    <img className='auth-right-image' src={image} alt="" />
                    <img onClick={handleClose} className='close-icon' src={AuthClose} alt="" />
                </div>
            </AuthModalBody>
        </AuthModalBlock>
    )
}

export default AuthModal