import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { useTheme } from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CheckoutBlock } from '../styles/styles'
import { PLANS } from '../utils/constants';

import { useCreateSubscriptionMutation } from '../api/api'

import StripeForm from '../components/StripeForm/StripeForm';
import Loader from '../components/Loader/Loader';
import Header from '../components/Header/Header'

import CheckoutCreditCardWhite from '../assets/icons/checkout-credit-card-white.svg'
import CheckoutBackMobileWhite from '../assets/icons/checkout-back-mobile-white.svg'
import CheckoutBackMobileBlack from '../assets/icons/checkout-back-mobile-black.svg'
import CheckoutCreditCard from '../assets/icons/checkout-credit-card.svg'
import CancelAnytimeWhite from '../assets/icons/cancel-anytime-white.svg'
import PoweredByStripeBlack from '../assets/powered-by-stripe-black.png'
import PoweredByStripeWhite from '../assets/powered-by-stripe-white.png'
import CancelAnytimeDark from '../assets/icons/cancel-anytime-dark.svg'
import MoneyBackWhite from '../assets/icons/money-back-white.svg'
import CheckoutPaypal from '../assets/icons/checkout-paypal.svg'
import MoneyBackDark from '../assets/icons/money-back-dark.svg'

const stripePromise = loadStripe("pk_test_51N64VIGtupj8Z7jUf0GWLkkxBnaTDtgHsSKAictepHloA69omH7eGMf36ebT03HrUpBqcvLV8QWyfhkFi5krDTl200dn45npZY");

const Checkout = () => {
    const [secret, setSecret] = useState('')
    const [planPrice, setPlanPrice] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState({})
    const user = useSelector(state => state.app.user)

    const navigate = useNavigate()

    const { secret: priceId, plan } = useParams()
    const { isDark } = useTheme()

    const clientSecret = secret ? secret : ''

    const [createSubscription] = useCreateSubscriptionMutation()

    const appearance = {
        theme: 'none',
        variables: {
            spacingUnit: '4px',
        },
        rules: {
            '.Tab': {
                border: isDark ? '1px solid #5E5E5E' : '1px solid #CDCDCD',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                backgroundColor: isDark ? '' : '',
            },

            '.Input': {
                border: isDark ? '1px solid #5E5E5E' : '1px solid #E5E5E5',
                backgroundColor: isDark ? '#2E2E2E' : '#FFFFFF',
                outline: 'none',
                marginBottom: '3px',
                color: isDark ? '#FFFFFF' : '#1E1E1E',
            },

            '.Label': {
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '18px',
                color: isDark ? '#FFF' : '#1E1E1E',
                fontVariant: 'normal',
                marginBottom: '3px'
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: isDark ? '#FFFFFF' : '#5E5E5E',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            '.Input::placeholder': {
                color: '#5E5E5E',
                fontSize: '16px',
            },

            '.Dropdown': {
                backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
                color: '#1E1E1E',
            },

            '.DropdownItem': {
                color: isDark ? '#1E1E1E' : '#1E1E1E',
            },

            '.Block': {
                backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
                color: isDark ? '#FFFFFF' : '#1E1E1E',
                border: isDark ? '1px solid #5E5E5E' : '1px solid #CDCDCD',
            },

            '.PickerItem': {
                backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
                color: isDark ? '#FFFFFF' : '#1E1E1E',
                border: isDark ? '1px solid #5E5E5E' : '1px solid #CDCDCD',
            },

            '.TermsText': {
                color: '#A0A0A0',
                fontSize: '12px',
                fontWeight: '500',
            },

            '.TabIcon': {
                fill: isDark ? '#FFFFFF' : '#1E1E1E',
            },

            '.TabLabel': {
                color: isDark ? '#FFFFFF' : '#1E1E1E',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
    };

    const handleBackToPlans = () => {
        navigate('/plans')
    }

    useEffect(() => {
        if (plan) {
            setSelectedPlan(PLANS.find((el) => el.name === plan))
        }
    }, [plan])

    useEffect(() => {
        if (user) {
            (async () => {
                const res = await createSubscription({ price_id: priceId })
                const selectedPlan = PLANS.find((el) => el.name === plan)

                if (selectedPlan) {
                    setPlanPrice(selectedPlan.price)
                }

                setSecret(res.data.pi_secret)
            })()
        }
    }, [priceId, createSubscription, plan, user])

    return (
        <CheckoutBlock>
            <Header withBorder={true} />
            <div className='checkout-form-wrapper'>
                {clientSecret ? (
                    <Elements options={options} stripe={stripePromise}>
                        <StripeForm priceId={priceId} planName={plan} />
                    </Elements>
                ) : (
                    <div className="checkout-page-loader-wrapper">
                        <div className="checkout-page-loader pb-4">
                            <div className="checkout-body">
                                <div className="checkout-field-block">
                                    <div className='checkout-new-title-powered'>
                                        <div className="checkout-body-header">Secure Checkout</div>
                                        {isDark ? (
                                            <img width={120} src={PoweredByStripeWhite} alt="" />
                                        ) : (
                                            <img width={120} src={PoweredByStripeBlack} alt="" />
                                        )}
                                    </div>
                                    <div className="checkout-card-field">
                                        <span className="checkout-new-payment-method-title">
                                            Payment Method
                                        </span>
                                        <div className='select-payments-block-wrapper'>
                                            <div className={`selecet-payment-method-element active`}>
                                                {isDark ? (
                                                    <img src={CheckoutCreditCardWhite} alt="" />
                                                ) : (
                                                    <img src={CheckoutCreditCard} alt="" />
                                                )}
                                                <span className='select-payment-method-name'>Credit Card</span>
                                            </div>
                                            <div className={`selecet-payment-method-element`}>
                                                <img src={CheckoutPaypal} alt="" />
                                                <span className='select-payment-method-name'>PayPal</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-center py-3'>
                                        <Loader />
                                    </div>
                                </div>
                                <div className="checkout-body-right">
                                    <div className='checkout-guarantee-block disable-on-desktop'>
                                        <span className='new-flow-checkout-text'>
                                            By clicking “Purchase” you agree to be charged $47.40 now and accept our Terms of Use and Privacy Policy. You will be billed $47.40 each year. You can cancel anytime.
                                        </span>
                                    </div>
                                    <div className='checkout-guarantee-underline disable-on-desktop'></div>
                                    <div className='checkout-guarantee-block'>
                                        <div className='checkout-guarantee-icon-wrapper'>
                                            {isDark ? (
                                                <img src={MoneyBackDark} alt="" />
                                            ) : (
                                                <img src={MoneyBackWhite} alt="" />
                                            )}
                                        </div>
                                        <span className='checkout-guarantee-block-title'>Money-back guarantee</span>
                                        <span className='checkout-guarantee-block-text'>If you are not fully satisfied, just let us know and we will happily process a full refund.</span>
                                    </div>
                                    <div className='checkout-guarantee-underline'></div>
                                    <div className='checkout-guarantee-block'>
                                        <div className='checkout-guarantee-icon-wrapper'>
                                            {isDark ? (
                                                <img src={CancelAnytimeDark} alt="" />
                                            ) : (
                                                <img src={CancelAnytimeWhite} alt="" />
                                            )}
                                        </div>
                                        <span className='checkout-guarantee-block-title'>Cancel anytime</span>
                                        <span className='checkout-guarantee-block-text'>You may cancel anytime from your dashboard, or by email.</span>
                                    </div>
                                    <div style={{ marginBottom: '72px' }} className='checkout-guarantee-underline disable-on-desktop'></div>
                                </div>
                                <div onClick={handleBackToPlans} className='secure-checkout-block-mobile'>
                                    {isDark ? (
                                        <img src={CheckoutBackMobileWhite} alt="" />
                                    ) : (
                                        <img src={CheckoutBackMobileBlack} alt="" />
                                    )}
                                    <span className='secure-checkout-text-mobile'>Secure Checkout</span>
                                    <span></span>
                                </div>
                                <div className='change-selected-plan-block-mobile'>
                                    <div className='change-selected-plan-block-info'>
                                        <span className='change-selected-plan-block-title'>Selected Package</span>
                                        <span className='change-selected-plan-block-name'>{selectedPlan.name} {selectedPlan.type}</span>
                                    </div>
                                    <span className='change-selected-plan-block-change-btn'>Change Package</span>
                                </div>
                                <div className='checkout-new-purchase-block'>
                                    <div className='checkout-new-due-total-block w-full'>
                                        <span className='checkout-new-due-total-title'>Due today:</span>
                                        <span className='checkout-new-due-total-price'>${planPrice}</span>
                                    </div>
                                    <button
                                        id="submit"
                                        className="checkout-new-purchase-button relative"
                                    >
                                        Purchase
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </CheckoutBlock>
    )
}

export default Checkout