import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./config";

export const invalidateOn = ({ success, error }) => {
    return (result) => result ? success : error
}

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    tagTypes: ['Logo', 'Profile', 'SavedLogos', 'RecentDesigns', 'PaymentMethods'],
    endpoints: builder => ({
        createLogos: builder.query({
            query: ({ limit, query, company_name, slogan, color_type, font_group, layout }) => {
                return {
                    url: `/logo_templates/create_logo?limit=${limit}&keywords=${query}&company_name=${company_name}&slogan=${slogan}`,
                    method: 'POST',
                    body: {
                        color_type: color_type && color_type.length > 0 ? color_type : null,
                        font_group: font_group && font_group.length > 0 ? font_group : null,
                        layout: layout && layout.length > 0 ? layout : null,
                    }
                }
            }
        }),
        getLogoById: builder.query({
            query: (id) => {
                return {
                    url: `/logo_templates/${id}`,
                    method: 'GET',
                }
            },
            providesTags: ['Logo']
        }),
        updateLogo: builder.mutation({
            query: (data) => {
                return {
                    url: `/logo_templates/${data.id}/edit_logo`,
                    method: 'PUT',
                    body: data,
                }
            },
            invalidatesTags: ['SavedLogos']
        }),
        getIcons: builder.query({
            query: ({ keyword, limit }) => {
                return {
                    url: `/logo_templates/icons?keyword=${keyword}&limit=${limit}`,
                    method: 'GET',
                }
            }
        }),
        getUserProfile: builder.query({
            query: () => {
                return {
                    url: `/profiles/me`,
                    method: 'GET',
                }
            },
            providesTags: ['Profile']
        }),
        getSavedLogos: builder.query({
            query: () => {
                return {
                    url: `/logo_templates/saved`,
                    method: 'GET',
                }
            },
            providesTags: ['SavedLogos']
        }),
        unsaveLogo: builder.mutation({
            query: (id) => {
                return {
                    url: `/logo_templates/${id}/edit_logo`,
                    method: 'PUT',
                    body: {
                        saved: false,
                    }
                }
            },
            invalidatesTags: ['SavedLogos']
        }),
        getRecentDesigns: builder.query({
            query: (limit) => {
                return {
                    url: `/logo_templates/recent?limit=${limit}`,
                    method: 'GET',
                }
            },
            providesTags: ['RecentDesigns']
        }),
        createSubscription: builder.mutation({
            query: (data) => {
                return {
                    url: `/subscriptions/create`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        deleteUserAccount: builder.mutation({
            query: () => {
                return {
                    url: `/profiles/me`,
                    method: 'DELETE',
                }
            },
        }),
        getInvoices: builder.query({
            query: () => {
                return {
                    url: `/subscriptions/invoices`,
                    method: 'GET',
                }
            },
        }),
        getInvoiceById: builder.query({
            query: (id) => {
                return {
                    url: `/subscriptions/invoices/${id}`,
                    method: 'GET',
                }
            },
        }),
        getInvoiceByPaymentIntentId: builder.query({
            query: ({ paymentIntentId }) => {
                return {
                    url: `/subscriptions/invoices/pi/${paymentIntentId}`,
                    method: 'GET',
                }
            },
        }),
        sendInvoiceEmail: builder.mutation({
            query: (id) => {
                return {
                    url: `/subscriptions/invoice/${id}/send`,
                    method: 'GET',
                }
            },
        }),
        getPaymentMethods: builder.query({
            query: () => {
                return {
                    url: `/subscriptions/payment_methods`,
                    method: 'GET',
                }
            },
            providesTags: ['PaymentMethods']
        }),
        addPaymentMethod: builder.mutation({
            query: ({ payment_method_id }) => {
                return {
                    url: `/subscriptions/add_payment_method?payment_method_id=${payment_method_id}`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: ['PaymentMethods']
        }),
        deletePaymentMethod: builder.mutation({
            query: (id) => {
                return {
                    url: `/subscriptions/payment_methods/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['PaymentMethods']
        }),
        setDefaultPaymentMethod: builder.mutation({
            query: (id) => {
                return {
                    url: `/subscriptions/set_customer_default_payment_method/${id}`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['PaymentMethods', 'Profile']
        }),
        updateSubscription: builder.mutation({
            query: (data) => {
                return {
                    url: `/subscriptions/update_subscription`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Profile']
        }),
        cancelSubscription: builder.mutation({
            query: (data) => {
                return {
                    url: `/subscriptions/cancel_subscription`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Profile']
        }),
        updateUserEmail: builder.mutation({
            query: (data) => {
                return {
                    url: `/profiles/me/email?email=${data.email}`,
                    method: 'PUT',
                    body: data,
                }
            },
            invalidatesTags: ['Profile']
        }),
        downloadDone: builder.mutation({
            query: ({ logo_id }) => {
                return {
                    url: `/profiles/download_done?logo_id=${logo_id}`,
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: ['Profile']
        }),
    })
})

export const {
    useCreateLogosQuery,
    useGetLogoByIdQuery,
    useUpdateLogoMutation,
    useGetIconsQuery,
    useGetUserProfileQuery,
    useGetSavedLogosQuery,
    useUnsaveLogoMutation,
    useGetRecentDesignsQuery,
    useCreateSubscriptionMutation,
    useDeleteUserAccountMutation,
    useGetInvoiceByIdQuery,
    useGetInvoicesQuery,
    useGetInvoiceByPaymentIntentIdQuery,
    useSendInvoiceEmailMutation,
    useGetPaymentMethodsQuery,
    useAddPaymentMethodMutation,
    useDeletePaymentMethodMutation,
    useSetDefaultPaymentMethodMutation,
    useUpdateSubscriptionMutation,
    useCancelSubscriptionMutation,
    useUpdateUserEmailMutation,
    useDownloadDoneMutation,
} = api